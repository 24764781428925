import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Modal from "../utils/Modal";

import { ReactComponent as Shadow } from "../assets/sensei/levitating-shadow.svg";
import { ReactComponent as RubyGem } from "../assets/gems/ruby.svg";
import { ReactComponent as CitrineGem } from "../assets/gems/citrine.svg";
import { ReactComponent as TopazGem } from "../assets/gems/topaz.svg";
import { ReactComponent as EmeraldGem } from "../assets/gems/emerald.svg";
import { ReactComponent as AquamarineGem } from "../assets/gems/aquamarine.svg";
import { ReactComponent as TanzaniteGem } from "../assets/gems/tanzanite.svg";
import { ReactComponent as AmethystGem } from "../assets/gems/amethyst.svg";
import { ReactComponent as DownArrow } from "../assets/icons/arrows/down.svg";
import { useOnScreen } from "../hooks";
import LevitatingSensei from "../components/LevitatingSensei";
import { showMeTheWay } from "../utils/helpers";

function HeroHome() {
  const [deckModalOpen, setDeckModalOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [isVideoStarted, setIsVideoStarted] = useState(false);

  const history = useHistory();
  const phoneRef = useRef();
  const isPhoneVisible = useOnScreen(phoneRef);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const [count, setCount] = useState(1);

  useEffect(() => {
    setCount(1);
  }, [count]);

  return (
    <section>
      <div
        className="absolute left-0 bottom-0 -ml-20 hidden lg:block pointer-events-none"
        aria-hidden="true"
        data-aos="fade-up"
        data-aos-delay="400"
      ></div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        {/* Illustration behind hero content */}

        {/* Hero content */}
        <div className="relative pt-32 pb-10 md:pt-40 md:pb-16">
          {/* Section header */}
          <div className="mx-auto text-center pb-12 md:pb-16">
            {/* Desktop and Tablet */}
            <div className="relative md:flex hidden justify-between w-full ">
              <div className="flex flex-col w-3/5 ">
                <div
                  className="text-6xl md:leading-normal h4 mt-28 mb-3 text-white text-left"
                  data-aos="fade-up"
                  data-aos-delay="1000"
                >
                  Generosity should be more generative...
                </div>
                <div
                  className="text-left mt-3 md:text-2xl text-xl font-thin text-white"
                  data-aos="fade-up"
                  data-aos-delay="1500"
                >
                  Generos taps into the generative nature of generosity,
                  <span className="italic font-bold text-primary-1">
                    {` empowering givers `}
                  </span>{" "}
                  to multiply their resources, champion great causes and earn
                  while participating.
                </div>
                <div
                  className="flex justify-start mt-12"
                  data-aos="fade-up"
                  data-aos-delay="2000"
                >
                  <a
                    className="btn md:text-lg rounded-lg font-bold text-primary-0 bg-primary-4 hover:bg-gray-800 w-full mb-4 sm:w-auto sm:mb-0"
                    href={process.env.REACT_APP_GENEROS_DOCS_URI}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More
                  </a>
                </div>
              </div>
              <div
                className="flex flex-col justify-center w-1/2 mb-12"
                data-aos="fade-up"
                data-aos-delay="1500"
              >
                {/* <LevitatingSensei className="animate-levitate" />
                <Shadow className="px-12 animate-stretchShadow" /> */}
                {/* <PhoneProfile /> */}
                <img
                  className="w-full md:px-10"
                  src={require(`../images/phone/peak-profile.png`).default}
                  alt="phone-profile"
                />
              </div>
              <div
                className={`absolute bottom-0 md:flex justify-center w-full hidden ${
                  isPhoneVisible ? "opacity-0" : ""
                }`}
              >
                <DownArrow className="animate-bounce w-16 fill-current text-primary-1" />
              </div>
            </div>
            {/* Mobile */}
            <div className="relative justify-between w-full">
              <div className=" md:hidden flex flex-col justify-cente items-center">
                <div
                  className="text-4xl h4 md:leading-normal mb-6 text-white text-center"
                  data-aos="fade-up"
                  data-aos-delay="250"
                >
                  Generosity should be more generative...
                </div>
                <div
                  className="mt-3 md:text-2xl text-xl font-thin text-white text-center"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  Generos taps into the generative nature of generosity,
                  <span className="italic font-bold text-primary-1">
                    {` empowering givers `}
                  </span>{" "}
                  to multiply their resources, champion great causes and earn
                  while participating.
                </div>
                <div
                  className="flex flex-col justify-center w-full mt-6"
                  data-aos="fade-up"
                  data-aos-delay="1000"
                >
                  {/* <PhoneProfile /> */}
                  <img
                    className="w-full md:px-10"
                    src={require(`../images/phone/peak-profile.png`).default}
                    alt="phone-profile"
                  />
                  {/* <LevitatingSensei className="animate-levitate" />
                  <Shadow className="px-6 animate-stretchShadow" /> */}
                </div>

                <div
                  className="flex justify-start mt-12"
                  data-aos="fade-up"
                  data-aos-delay="1000"
                >
                  <a
                    className="btn md:text-lg rounded-lg font-bold text-primary-0 bg-primary-4 hover:bg-gray-800 w-full mb-4 sm:w-auto sm:mb-0"
                    href={process.env.REACT_APP_GENEROS_DOCS_URI}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Hero image */}
          <div
            className="flex justify-between md:mx-20 mx-6 mb-8"
            data-aos-id-gems-anchor
          >
            <RubyGem
              className="md:w-12 w-6"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-gamified-anchor]"
              data-aos-delay="400"
            />
            <CitrineGem
              className="md:w-12 w-6"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-gamified-anchor]"
              data-aos-delay="800"
            />
            <TopazGem
              className="md:w-12 w-6"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-gamified-anchor]"
              data-aos-delay="1200"
            />
            <EmeraldGem
              className="md:w-12 w-6"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-gamified-anchor]"
              data-aos-delay="1600"
            />
            <AquamarineGem
              className="md:w-12 w-6"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-gamified-anchor]"
              data-aos-delay="2000"
            />
            <TanzaniteGem
              className="md:w-12 w-6"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-gamified-anchor]"
              data-aos-delay="2400"
            />
            <AmethystGem
              className="md:w-12 w-6"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-gamified-anchor]"
              data-aos-delay="2800"
              ref={phoneRef}
            />
          </div>

          <Modal
            id="modal"
            ariaLabel="modal-headline"
            show={deckModalOpen}
            handleClose={() => setDeckModalOpen(false)}
          >
            <div className="reFlative pb-9/16">
              <iframe
                src="https://slides.com/patricklewis/generos-io/embed?token=FCRoUuML&style=hidden"
                className="absolute w-full h-full"
                title="Generos (IO)"
                scrolling="no"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe>
            </div>
          </Modal>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
